export const deliveryIcon = {
	C: 'fast-food-outline',
	D: 'document-text-outline',
	P: 'bag-handle-outline',
};
export const deliveryClass = {
	C: 'food',
	D: 'doc',
	P: 'package',
};
export const deliveryType = {
	C: 'Consumable',
	D: 'Document',
	P: 'Package',
};
export class DeliveryLog {
	public static STATUS = {
		SIGNED_IN: 'SI',
		SIGNED_OUT: 'SO',
		RECEIVED: "RC"
	}
	
	public static CATEGORY = {
		PACKAGE: 'P',
		DOCUMENT: 'D',
		CONSUMABLE: 'C'
	};

	public static DESTINATION = {
		FRONTDESK: 'FD',
		MAILROOM: 'MR',
		USER: 'U'
	};

	public static RECIPIENT = {
		USER: 'U',
		GROUP: 'G'
	};

	id: number;
	sortDate: any;
	recipientId: string;
	recipientName: string;
	recipientInitials: string;
	recipientType: string;
	recipientPhoto: string;
	destination: string;
	timeIn: string;
	timeOut: string;
	expectedTimeOut: string;
	category: string;
	description: string;
	senderId: string;
	senderName: string;
	senderInitials: string;
	senderEmail: string;
	senderPhone: string;
	dispatchName: string;
	tagNumber: string;
	status: string;
	hasSeen: boolean;
	receiverId: string;
	clientId: number;
	locationId: number;
	lastModified: string;
	inTimestamp: any;
	outTimestamp: any;
	
	constructor(
		id: number,
		sortDate: any,
		recipientId: string,
		recipientName: string,
		recipientType: string,
		destination: string,
		timeIn: string,
		timeOut: string,
		expectedTimeOut: string,
		category: string,
		description: string,
		senderId: string,
		senderName: string,
		senderEmail: string,
		senderPhone: string,
		dispatchName: string,
		tagNumber: string,
		status: string,
		hasSeen: boolean,
		receiverId: string,
		locationId: number,
		clientId: number,
		lastModified: string
	) {
		this.id = id;
		this.sortDate = sortDate;
		this.recipientId = recipientId;
		this.recipientName = recipientName;
		this.recipientType = recipientType;
		this.destination = destination;
		this.timeIn = timeIn;
		this.timeOut = timeOut;
		this.expectedTimeOut = expectedTimeOut;
		this.category = category;
		this.description = description;
		this.senderId = senderId;
		this.senderName = senderName;
		this.senderEmail = senderEmail;
		this.senderPhone = senderPhone;
		this.dispatchName = dispatchName;
		this.tagNumber = tagNumber;
		this.status = status;
		this.hasSeen = hasSeen;
		this.receiverId = receiverId;
		this.locationId = locationId;
		this.clientId = clientId;
		this.lastModified = lastModified;
	}
		
}
