export class DeliverySender {
	id: number;
	senderId: string;
	initials: string;
	name: string;
	email: string;
	phone: string;
	address: string;
	photo: string;
	mergeId: string;
	
	constructor(
		id: number, 
		senderId: string, 
		name: string, 
		email: string, 
		phone: string, 
		address: string, 
		photo: string ,
		mergeId: string
		) {
		this.id = id;
		this.senderId = senderId;
		this.name = name;
		this.email = email;
		this.phone = phone;
		this.address = address;
		this.photo = photo;
		this.mergeId = mergeId;
	}
	
}
