export const deviceTypeOptions: any[] = [
	{
		id: 1,
		name: "Laptop",
		icon: "laptop-outline"
	},
	{
		id: 2,
		name: "Projector",
		icon: "projector"
	},
	{
		id: 3,
		name: "Monitor",
		icon: "monitor"
	},
	{
		id: 4,
		name: "Camera",
		icon: "camera"
	},
	{
		id: 5,
		name: "Modem",
		icon: "modem"
	},
	{
		id: 6,
		name: "Speaker",
		icon: "speaker"
	},
	{
		id: 7,
		name: "Sound System",
		icon: "sound-system"
	},
	{
		id: 8,
		name: "Video Recorder",
		icon: "camcorder"
	},
	{
		id: 9,
		name: "Desktop Computer",
		icon: "desktop-computer"
	},
	{
		id: 10,
		name: "Security Equipment",
		icon: "cctv-camera"
	},
	{
		id: 11,
		name: "Network Equipment",
		icon: "network-equipment"
	},
	{
		id: 12,
		name: "Office Equipment",
		icon: "copy-machine"
	},
	{
		id: 13,
		name: "Other",
		icon: "add-circle-outline"
	}
];
