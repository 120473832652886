import { Injectable } from '@angular/core';
// import { AuthService } from './../_services/auth.service';
import { CanLoad, Router } from '@angular/router';
import { filter, map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Storage } from '@capacitor/storage';
import { AuthService } from "@/_services/auth.service";

export const CURRENT_USER_KEY = 'currentUser';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {
	constructor(private authService: AuthService, private router: Router) { 
		// console.log('init authGuard')
	}
	// canLoad(): boolean {
	// 	const currentUser = this.authService.currentUser.value;
	// 	// console.log('oloadi', route.path);

	// 	if (!currentUser) {
	// 		this.router.navigate(["/login"]);
	// 		return false;
	// 	}
	// }

	// canLoad(): Observable<boolean> {    
	// 	return this.authService.currentUser.pipe(
	// 		filter(val => val !== null), // Filter out initial Behaviour subject value
	// 		take(1), // Otherwise the Observable doesn't complete!
	// 		map(currentUser => {
	// 			if (currentUser) {   
	// 				console.log('authCurr', currentUser);       
	// 				return true;
	// 			} else {          
	// 				console.log('authCurr koPoss', currentUser);       
	// 				this.router.navigateByUrl('/login', { replaceUrl: true })
	// 				return false;
	// 			}
	// 		})
	// 	);
	// }

	// canLoad(route: Route, segments: UrlSegment[]): boolean {
	canLoad(): boolean {
		const currentUser = this.authService.currentUser.value;

		if (!currentUser) {
			this.router.navigate(["/login"]);
			return false;
		}

		if (this.authService.isAuthenticated.value) {
			return true;
		}

		return false;
	}


	async canActivate(): Promise<boolean> {
		const currentUser = await Storage.get({ key: CURRENT_USER_KEY });  
		// console.log('curr', currentUser);
		if (currentUser && (currentUser.value !== null)) {
			// console.log('currPoss', currentUser);
			return true;
		} else {
			// console.log('curr koPoss', currentUser);
			this.router.navigateByUrl('/login', { replaceUrl: true });
			return false;
		}
	}

}