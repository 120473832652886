export const countryOptions = [
	{
		id: 1,
		name: "Afghanistan",
		isoCode2: "AF",
		isoCode3: "AFG",
		phonePrefix: "+93",
	},
	{
		id: 2,
		name: "Albania",
		isoCode2: "AL",
		isoCode3: "ALB",
		phonePrefix: "+355",
	},
	{
		id: 3,
		name: "Algeria",
		isoCode2: "DZ",
		isoCode3: "DZA",
		phonePrefix: "+213",
	},
	{
		id: 4,
		name: "American Samoa",
		isoCode2: "AS",
		isoCode3: "ASM",
		phonePrefix: "+684",
	},
	{
		id: 5,
		name: "Andorra",
		isoCode2: "AD",
		isoCode3: "AND",
		phonePrefix: "+376",
	},
	{
		id: 6,
		name: "Angola",
		isoCode2: "AO",
		isoCode3: "AGO",
		phonePrefix: "+244",
	},
	{
		id: 7,
		name: "Anguilla",
		isoCode2: "AI",
		isoCode3: "AIA",
		phonePrefix: "+264",
	},
	{
		id: 8,
		name: "Antarctica",
		isoCode2: "AQ",
		isoCode3: "ATA",
		phonePrefix: "+672",
	},
	{
		id: 9,
		name: "Antigua & Barbuda",
		isoCode2: "AG",
		isoCode3: "ATG",
		phonePrefix: "+268",
	},
	{
		id: 10,
		name: "Argentina",
		isoCode2: "AR",
		isoCode3: "ARG",
		phonePrefix: "+54",
	},
	{
		id: 11,
		name: "Armenia",
		isoCode2: "AM",
		isoCode3: "ARM",
		phonePrefix: "+374",
	},
	{
		id: 12,
		name: "Aruba",
		isoCode2: "AW",
		isoCode3: "ABW",
		phonePrefix: "+297",
	},
	{
		id: 13,
		name: "Australia",
		isoCode2: "AU",
		isoCode3: "AUS",
		phonePrefix: "+61",
	},
	{
		id: 14,
		name: "Austria",
		isoCode2: "AT",
		isoCode3: "AUT",
		phonePrefix: "+43",
	},
	{
		id: 15,
		name: "Azerbaijan",
		isoCode2: "AZ",
		isoCode3: "AZE",
		phonePrefix: "+994",
	},
	{
		id: 16,
		name: "Bahamas",
		isoCode2: "BS",
		isoCode3: "BHS",
		phonePrefix: "+1",
	},
	{
		id: 17,
		name: "Bahrain",
		isoCode2: "BH",
		isoCode3: "BHR",
		phonePrefix: "+973",
	},
	{
		id: 18,
		name: "Bangladesh",
		isoCode2: "BD",
		isoCode3: "BGD",
		phonePrefix: "+880",
	},
	{
		id: 19,
		name: "Barbados",
		isoCode2: "BB",
		isoCode3: "BRB",
		phonePrefix: "+246",
	},
	{
		id: 20,
		name: "Belarus",
		isoCode2: "BY",
		isoCode3: "BLR",
		phonePrefix: "+375",
	},
	{
		id: 21,
		name: "Belgium",
		isoCode2: "BE",
		isoCode3: "BEL",
		phonePrefix: "+32",
	},
	{
		id: 22,
		name: "Belize",
		isoCode2: "BZ",
		isoCode3: "BLZ",
		phonePrefix: "+501",
	},
	{
		id: 23,
		name: "Benin",
		isoCode2: "BJ",
		isoCode3: "BEN",
		phonePrefix: "+229",
	},
	{
		id: 24,
		name: "Bermuda",
		isoCode2: "BM",
		isoCode3: "BMU",
		phonePrefix: "+1",
	},
	{
		id: 25,
		name: "Bhutan",
		isoCode2: "BT",
		isoCode3: "BTN",
		phonePrefix: "+975",
	},
	{
		id: 26,
		name: "Bolivia",
		isoCode2: "BO",
		isoCode3: "BOL",
		phonePrefix: "+591",
	},
	{
		id: 27,
		name: "Bonaire, Sint Eustatius & Saba",
		isoCode2: "BQ",
		isoCode3: "BES",
		phonePrefix: "+599",
	},
	{
		id: 28,
		name: "Bosnia & Herzegovina",
		isoCode2: "BA",
		isoCode3: "BIH",
		phonePrefix: "+387",
	},
	{
		id: 29,
		name: "Botswana",
		isoCode2: "BW",
		isoCode3: "BWA",
		phonePrefix: "+267",
	},
	{
		id: 30,
		name: "Bouvet Island",
		isoCode2: "BV",
		isoCode3: "BVT",
		phonePrefix: "+47",
	},
	{
		id: 31,
		name: "Brazil",
		isoCode2: "BR",
		isoCode3: "BRA",
		phonePrefix: "+55",
	},
	{
		id: 32,
		name: "British Indian Ocean Territory",
		isoCode2: "IO",
		isoCode3: "IOT",
		phonePrefix: "+246",
	},
	{
		id: 33,
		name: "Brunei",
		isoCode2: "BN",
		isoCode3: "BRN",
		phonePrefix: "+673",
	},
	{
		id: 34,
		name: "Bulgaria",
		isoCode2: "BG",
		isoCode3: "BGR",
		phonePrefix: "+359",
	},
	{
		id: 35,
		name: "Burkina Faso",
		isoCode2: "BF",
		isoCode3: "BFA",
		phonePrefix: "+226",
	},
	{
		id: 36,
		name: "Burundi",
		isoCode2: "BI",
		isoCode3: "BDI",
		phonePrefix: "+257",
	},
	{
		id: 37,
		name: "Cabo Verde",
		isoCode2: "CV",
		isoCode3: "CPV",
		phonePrefix: "+238",
	},
	{
		id: 38,
		name: "Cambodia",
		isoCode2: "KH",
		isoCode3: "KHM",
		phonePrefix: "+855",
	},
	{
		id: 39,
		name: "Cameroon",
		isoCode2: "CM",
		isoCode3: "CMR",
		phonePrefix: "+237",
	},
	{
		id: 40,
		name: "Canada",
		isoCode2: "CA",
		isoCode3: "CAN",
		phonePrefix: "+1",
	},
	{
		id: 41,
		name: "Cayman Islands",
		isoCode2: "KY",
		isoCode3: "CYM",
		phonePrefix: "+345",
	},
	{
		id: 42,
		name: "Central African Republic",
		isoCode2: "CF",
		isoCode3: "CAF",
		phonePrefix: "+236",
	},
	{
		id: 43,
		name: "Chad",
		isoCode2: "TD",
		isoCode3: "TCD",
		phonePrefix: "+235",
	},
	{
		id: 44,
		name: "Chile",
		isoCode2: "CL",
		isoCode3: "CHL",
		phonePrefix: "+56",
	},
	{
		id: 45,
		name: "China",
		isoCode2: "CN",
		isoCode3: "CHN",
		phonePrefix: "+86",
	},
	{
		id: 46,
		name: "Christmas Island",
		isoCode2: "CX",
		isoCode3: "CXR",
		phonePrefix: "+61",
	},
	{
		id: 47,
		name: "Cocos Islands",
		isoCode2: "CC",
		isoCode3: "CCK",
		phonePrefix: "+61",
	},
	{
		id: 48,
		name: "Colombia",
		isoCode2: "CO",
		isoCode3: "COL",
		phonePrefix: "+57",
	},
	{
		id: 49,
		name: "Comoros",
		isoCode2: "KM",
		isoCode3: "COM",
		phonePrefix: "+269",
	},
	{
		id: 50,
		name: "Congo-Brazzaville",
		isoCode2: "CG",
		isoCode3: "COG",
		phonePrefix: "+242",
	},
	{
		id: 51,
		name: "Congo-Kinshasa",
		isoCode2: "CD",
		isoCode3: "COD",
		phonePrefix: "+243",
	},
	{
		id: 52,
		name: "Cook Islands",
		isoCode2: "CK",
		isoCode3: "COK",
		phonePrefix: "+682",
	},
	{
		id: 53,
		name: "Costa Rica",
		isoCode2: "CR",
		isoCode3: "CRI",
		phonePrefix: "+506",
	},
	{
		id: 54,
		name: "Croatia",
		isoCode2: "HR",
		isoCode3: "HRV",
		phonePrefix: "+385",
	},
	{
		id: 55,
		name: "Cuba",
		isoCode2: "CU",
		isoCode3: "CUB",
		phonePrefix: "+53",
	},
	{
		id: 56,
		name: "Curaçao",
		isoCode2: "CW",
		isoCode3: "CUW",
		phonePrefix: "+599",
	},
	{
		id: 57,
		name: "Cyprus",
		isoCode2: "CY",
		isoCode3: "CYP",
		phonePrefix: "+357",
	},
	{
		id: 58,
		name: "Czech Republic",
		isoCode2: "CZ",
		isoCode3: "CZE",
		phonePrefix: "+420",
	},
	{
		id: 59,
		name: "Côte d-Ivoire",
		isoCode2: "CI",
		isoCode3: "CIV",
		phonePrefix: "+225",
	},
	{
		id: 60,
		name: "Denmark",
		isoCode2: "DK",
		isoCode3: "DNK",
		phonePrefix: "+45",
	},
	{
		id: 61,
		name: "Djibouti",
		isoCode2: "DJ",
		isoCode3: "DJI",
		phonePrefix: "+253",
	},
	{
		id: 62,
		name: "Dominica",
		isoCode2: "DM",
		isoCode3: "DMA",
		phonePrefix: "+767",
	},
	{
		id: 63,
		name: "Dominican Republic",
		isoCode2: "DO",
		isoCode3: "DOM",
		phonePrefix: "+1",
	},
	{
		id: 64,
		name: "Ecuador",
		isoCode2: "EC",
		isoCode3: "ECU",
		phonePrefix: "+593",
	},
	{
		id: 65,
		name: "Egypt",
		isoCode2: "EG",
		isoCode3: "EGY",
		phonePrefix: "+20",
	},
	{
		id: 66,
		name: "El Salvador",
		isoCode2: "SV",
		isoCode3: "SLV",
		phonePrefix: "+503",
	},
	{
		id: 67,
		name: "Equatorial Guinea",
		isoCode2: "GQ",
		isoCode3: "GNQ",
		phonePrefix: "+240",
	},
	{
		id: 68,
		name: "Eritrea",
		isoCode2: "ER",
		isoCode3: "ERI",
		phonePrefix: "+291",
	},
	{
		id: 69,
		name: "Estonia",
		isoCode2: "EE",
		isoCode3: "EST",
		phonePrefix: "+372",
	},
	{
		id: 70,
		name: "Ethiopia",
		isoCode2: "ET",
		isoCode3: "ETH",
		phonePrefix: "+251",
	},
	{
		id: 71,
		name: "Falkland Islands",
		isoCode2: "FK",
		isoCode3: "FLK",
		phonePrefix: "+500",
	},
	{
		id: 72,
		name: "Faroe Islands",
		isoCode2: "FO",
		isoCode3: "FRO",
		phonePrefix: "+298",
	},
	{
		id: 73,
		name: "Fiji",
		isoCode2: "FJ",
		isoCode3: "FJI",
		phonePrefix: "+679",
	},
	{
		id: 74,
		name: "Finland",
		isoCode2: "FI",
		isoCode3: "FIN",
		phonePrefix: "+358",
	},
	{
		id: 75,
		name: "France",
		isoCode2: "FR",
		isoCode3: "FRA",
		phonePrefix: "+33",
	},
	{
		id: 76,
		name: "French Guiana",
		isoCode2: "GF",
		isoCode3: "GUF",
		phonePrefix: "+594",
	},
	{
		id: 77,
		name: "French Polynesia",
		isoCode2: "PF",
		isoCode3: "PYF",
		phonePrefix: "+689",
	},
	{
		id: 78,
		name: "French Southern Territories",
		isoCode2: "TF",
		isoCode3: "ATF",
		phonePrefix: "+262",
	},
	{
		id: 79,
		name: "Gabon",
		isoCode2: "GA",
		isoCode3: "GAB",
		phonePrefix: "+241",
	},
	{
		id: 80,
		name: "Gambia",
		isoCode2: "GM",
		isoCode3: "GMB",
		phonePrefix: "+220",
	},
	{
		id: 81,
		name: "Georgia",
		isoCode2: "GE",
		isoCode3: "GEO",
		phonePrefix: "+995",
	},
	{
		id: 82,
		name: "Germany",
		isoCode2: "DE",
		isoCode3: "DEU",
		phonePrefix: "+49",
	},
	{
		id: 83,
		name: "Ghana",
		isoCode2: "GH",
		isoCode3: "GHA",
		phonePrefix: "+233",
	},
	{
		id: 84,
		name: "Gibraltar",
		isoCode2: "GI",
		isoCode3: "GIB",
		phonePrefix: "+350",
	},
	{
		id: 85,
		name: "Greece",
		isoCode2: "GR",
		isoCode3: "GRC",
		phonePrefix: "+30",
	},
	{
		id: 86,
		name: "Greenland",
		isoCode2: "GL",
		isoCode3: "GRL",
		phonePrefix: "+299",
	},
	{
		id: 87,
		name: "Grenada",
		isoCode2: "GD",
		isoCode3: "GRD",
		phonePrefix: "+473",
	},
	{
		id: 88,
		name: "Guadeloupe",
		isoCode2: "GP",
		isoCode3: "GLP",
		phonePrefix: "+590",
	},
	{
		id: 89,
		name: "Guam",
		isoCode2: "GU",
		isoCode3: "GUM",
		phonePrefix: "+1",
	},
	{
		id: 90,
		name: "Guatemala",
		isoCode2: "GT",
		isoCode3: "GTM",
		phonePrefix: "+502",
	},
	{
		id: 91,
		name: "Guernsey",
		isoCode2: "GG",
		isoCode3: "GGY",
		phonePrefix: "+44",
	},
	{
		id: 92,
		name: "Guinea",
		isoCode2: "GN",
		isoCode3: "GIN",
		phonePrefix: "+224",
	},
	{
		id: 93,
		name: "Guinea-Bissau",
		isoCode2: "GW",
		isoCode3: "GNB",
		phonePrefix: "+245",
	},
	{
		id: 94,
		name: "Guyana",
		isoCode2: "GY",
		isoCode3: "GUY",
		phonePrefix: "+592",
	},
	{
		id: 95,
		name: "Haiti",
		isoCode2: "HT",
		isoCode3: "HTI",
		phonePrefix: "+509",
	},
	{
		id: 96,
		name: "Heard Island & McDonald Islands",
		isoCode2: "HM",
		isoCode3: "HMD",
		phonePrefix: "+672",
	},
	{
		id: 97,
		name: "Honduras",
		isoCode2: "HN",
		isoCode3: "HND",
		phonePrefix: "+504",
	},
	{
		id: 98,
		name: "Hong Kong",
		isoCode2: "HK",
		isoCode3: "HKG",
		phonePrefix: "+852",
	},
	{
		id: 99,
		name: "Hungary",
		isoCode2: "HU",
		isoCode3: "HUN",
		phonePrefix: "+36",
	},
	{
		id: 100,
		name: "Iceland",
		isoCode2: "IS",
		isoCode3: "ISL",
		phonePrefix: "+354",
	},
	{
		id: 101,
		name: "India",
		isoCode2: "IN",
		isoCode3: "IND",
		phonePrefix: "+91",
	},
	{
		id: 102,
		name: "Indonesia",
		isoCode2: "ID",
		isoCode3: "IDN",
		phonePrefix: "+62",
	},
	{
		id: 103,
		name: "Iran",
		isoCode2: "IR",
		isoCode3: "IRN",
		phonePrefix: "+98",
	},
	{
		id: 104,
		name: "Iraq",
		isoCode2: "IQ",
		isoCode3: "IRQ",
		phonePrefix: "+964",
	},
	{
		id: 105,
		name: "Ireland",
		isoCode2: "IE",
		isoCode3: "IRL",
		phonePrefix: "+353",
	},
	{
		id: 106,
		name: "Isle of Man",
		isoCode2: "IM",
		isoCode3: "IMN",
		phonePrefix: "+44",
	},
	{
		id: 107,
		name: "Israel",
		isoCode2: "IL",
		isoCode3: "ISR",
		phonePrefix: "+972",
	},
	{
		id: 108,
		name: "Italy",
		isoCode2: "IT",
		isoCode3: "ITA",
		phonePrefix: "+39",
	},
	{
		id: 109,
		name: "Jamaica",
		isoCode2: "JM",
		isoCode3: "JAM",
		phonePrefix: "+876",
	},
	{
		id: 110,
		name: "Japan",
		isoCode2: "JP",
		isoCode3: "JPN",
		phonePrefix: "+81",
	},
	{
		id: 111,
		name: "Jersey",
		isoCode2: "JE",
		isoCode3: "JEY",
		phonePrefix: "+44",
	},
	{
		id: 112,
		name: "Jordan",
		isoCode2: "JO",
		isoCode3: "JOR",
		phonePrefix: "+962",
	},
	{
		id: 113,
		name: "Kazakhstan",
		isoCode2: "KZ",
		isoCode3: "KAZ",
		phonePrefix: "+7",
	},
	{
		id: 114,
		name: "Kenya",
		isoCode2: "KE",
		isoCode3: "KEN",
		phonePrefix: "+254",
	},
	{
		id: 115,
		name: "Kiribati",
		isoCode2: "KI",
		isoCode3: "KIR",
		phonePrefix: "+686",
	},
	{
		id: 116,
		name: "Kuwait",
		isoCode2: "KW",
		isoCode3: "KWT",
		phonePrefix: "+965",
	},
	{
		id: 117,
		name: "Kyrgyzstan",
		isoCode2: "KG",
		isoCode3: "KGZ",
		phonePrefix: "+996",
	},
	{
		id: 118,
		name: "Laos",
		isoCode2: "LA",
		isoCode3: "LAO",
		phonePrefix: "+856",
	},
	{
		id: 119,
		name: "Latvia",
		isoCode2: "LV",
		isoCode3: "LVA",
		phonePrefix: "+371",
	},
	{
		id: 120,
		name: "Lebanon",
		isoCode2: "LB",
		isoCode3: "LBN",
		phonePrefix: "+961",
	},
	{
		id: 121,
		name: "Lesotho",
		isoCode2: "LS",
		isoCode3: "LSO",
		phonePrefix: "+266",
	},
	{
		id: 122,
		name: "Liberia",
		isoCode2: "LR",
		isoCode3: "LBR",
		phonePrefix: "+231",
	},
	{
		id: 123,
		name: "Libya",
		isoCode2: "LY",
		isoCode3: "LBY",
		phonePrefix: "+218",
	},
	{
		id: 124,
		name: "Liechtenstein",
		isoCode2: "LI",
		isoCode3: "LIE",
		phonePrefix: "+423",
	},
	{
		id: 125,
		name: "Lithuania",
		isoCode2: "LT",
		isoCode3: "LTU",
		phonePrefix: "+370",
	},
	{
		id: 126,
		name: "Luxembourg",
		isoCode2: "LU",
		isoCode3: "LUX",
		phonePrefix: "+352",
	},
	{
		id: 127,
		name: "Macao",
		isoCode2: "MO",
		isoCode3: "MAC",
		phonePrefix: "+853",
	},
	{
		id: 128,
		name: "Macedonia",
		isoCode2: "MK",
		isoCode3: "MKD",
		phonePrefix: "+389",
	},
	{
		id: 129,
		name: "Madagascar",
		isoCode2: "MG",
		isoCode3: "MDG",
		phonePrefix: "+261",
	},
	{
		id: 130,
		name: "Malawi",
		isoCode2: "MW",
		isoCode3: "MWI",
		phonePrefix: "+265",
	},
	{
		id: 131,
		name: "Malaysia",
		isoCode2: "MY",
		isoCode3: "MYS",
		phonePrefix: "+60",
	},
	{
		id: 132,
		name: "Maldives",
		isoCode2: "MV",
		isoCode3: "MDV",
		phonePrefix: "+960",
	},
	{
		id: 133,
		name: "Mali",
		isoCode2: "ML",
		isoCode3: "MLI",
		phonePrefix: "+223",
	},
	{
		id: 134,
		name: "Malta",
		isoCode2: "MT",
		isoCode3: "MLT",
		phonePrefix: "+356",
	},
	{
		id: 135,
		name: "Marshall Islands",
		isoCode2: "MH",
		isoCode3: "MHL",
		phonePrefix: "+692",
	},
	{
		id: 136,
		name: "Martinique",
		isoCode2: "MQ",
		isoCode3: "MTQ",
		phonePrefix: "+596",
	},
	{
		id: 137,
		name: "Mauritania",
		isoCode2: "MR",
		isoCode3: "MRT",
		phonePrefix: "+222",
	},
	{
		id: 138,
		name: "Mauritius",
		isoCode2: "MU",
		isoCode3: "MUS",
		phonePrefix: "+230",
	},
	{
		id: 139,
		name: "Mayotte",
		isoCode2: "YT",
		isoCode3: "MYT",
		phonePrefix: "+262",
	},
	{
		id: 140,
		name: "Mexico",
		isoCode2: "MX",
		isoCode3: "MEX",
		phonePrefix: "+52",
	},
	{
		id: 141,
		name: "Micronesia",
		isoCode2: "FM",
		isoCode3: "FSM",
		phonePrefix: "+691",
	},
	{
		id: 142,
		name: "Moldova",
		isoCode2: "MD",
		isoCode3: "MDA",
		phonePrefix: "+373",
	},
	{
		id: 143,
		name: "Monaco",
		isoCode2: "MC",
		isoCode3: "MCO",
		phonePrefix: "+377",
	},
	{
		id: 144,
		name: "Mongolia",
		isoCode2: "MN",
		isoCode3: "MNG",
		phonePrefix: "+976",
	},
	{
		id: 145,
		name: "Montenegro",
		isoCode2: "ME",
		isoCode3: "MNE",
		phonePrefix: "+382",
	},
	{
		id: 146,
		name: "Montserrat",
		isoCode2: "MS",
		isoCode3: "MSR",
		phonePrefix: "+1",
	},
	{
		id: 147,
		name: "Morocco",
		isoCode2: "MA",
		isoCode3: "MAR",
		phonePrefix: "+212",
	},
	{
		id: 148,
		name: "Mozambique",
		isoCode2: "MZ",
		isoCode3: "MOZ",
		phonePrefix: "+258",
	},
	{
		id: 149,
		name: "Myanmar",
		isoCode2: "MM",
		isoCode3: "MMR",
		phonePrefix: "+95",
	},
	{
		id: 150,
		name: "Namibia",
		isoCode2: "NA",
		isoCode3: "NAM",
		phonePrefix: "+264",
	},
	{
		id: 151,
		name: "Nauru",
		isoCode2: "NR",
		isoCode3: "NRU",
		phonePrefix: "+674",
	},
	{
		id: 152,
		name: "Nepal",
		isoCode2: "NP",
		isoCode3: "NPL",
		phonePrefix: "+977",
	},
	{
		id: 153,
		name: "Netherlands",
		isoCode2: "NL",
		isoCode3: "NLD",
		phonePrefix: "+31",
	},
	{
		id: 154,
		name: "New Caledonia",
		isoCode2: "NC",
		isoCode3: "NCL",
		phonePrefix: "+687",
	},
	{
		id: 155,
		name: "New Zealand",
		isoCode2: "NZ",
		isoCode3: "NZL",
		phonePrefix: "+64",
	},
	{
		id: 156,
		name: "Nicaragua",
		isoCode2: "NI",
		isoCode3: "NIC",
		phonePrefix: "+505",
	},
	{
		id: 157,
		name: "Niger",
		isoCode2: "NE",
		isoCode3: "NER",
		phonePrefix: "+227",
	},
	{
		id: 158,
		name: "Nigeria",
		isoCode2: "NG",
		isoCode3: "NGA",
		phonePrefix: "+234",
	},
	{
		id: 159,
		name: "Niue",
		isoCode2: "NU",
		isoCode3: "NIU",
		phonePrefix: "+683",
	},
	{
		id: 160,
		name: "Norfolk Island",
		isoCode2: "NF",
		isoCode3: "NFK",
		phonePrefix: "+672",
	},
	{
		id: 161,
		name: "Northern Mariana Islands",
		isoCode2: "MP",
		isoCode3: "MNP",
		phonePrefix: "+1",
	},
	{
		id: 162,
		name: "North Korea",
		isoCode2: "KP",
		isoCode3: "PRK",
		phonePrefix: "+850",
	},
	{
		id: 163,
		name: "Norway",
		isoCode2: "NO",
		isoCode3: "NOR",
		phonePrefix: "+47",
	},
	{
		id: 164,
		name: "Oman",
		isoCode2: "OM",
		isoCode3: "OMN",
		phonePrefix: "+968",
	},
	{
		id: 165,
		name: "Pakistan",
		isoCode2: "PK",
		isoCode3: "PAK",
		phonePrefix: "+92",
	},
	{
		id: 166,
		name: "Palau",
		isoCode2: "PW",
		isoCode3: "PLW",
		phonePrefix: "+680",
	},
	{
		id: 167,
		name: "Palestine",
		isoCode2: "PS",
		isoCode3: "PSE",
		phonePrefix: "+970",
	},
	{
		id: 168,
		name: "Panama",
		isoCode2: "PA",
		isoCode3: "PAN",
		phonePrefix: "+507",
	},
	{
		id: 169,
		name: "Papua New Guinea",
		isoCode2: "PG",
		isoCode3: "PNG",
		phonePrefix: "+675",
	},
	{
		id: 170,
		name: "Paraguay",
		isoCode2: "PY",
		isoCode3: "PRY",
		phonePrefix: "+595",
	},
	{
		id: 171,
		name: "Peru",
		isoCode2: "PE",
		isoCode3: "PER",
		phonePrefix: "+51",
	},
	{
		id: 172,
		name: "Philippines",
		isoCode2: "PH",
		isoCode3: "PHL",
		phonePrefix: "+63",
	},
	{
		id: 173,
		name: "Pitcairn",
		isoCode2: "PN",
		isoCode3: "PCN",
		phonePrefix: "+64",
	},
	{
		id: 174,
		name: "Poland",
		isoCode2: "PL",
		isoCode3: "POL",
		phonePrefix: "+48",
	},
	{
		id: 175,
		name: "Portugal",
		isoCode2: "PT",
		isoCode3: "PRT",
		phonePrefix: "+351",
	},
	{
		id: 176,
		name: "Puerto Rico",
		isoCode2: "PR",
		isoCode3: "PRI",
		phonePrefix: "+1",
	},
	{
		id: 177,
		name: "Qatar",
		isoCode2: "QA",
		isoCode3: "QAT",
		phonePrefix: "+974",
	},
	{
		id: 178,
		name: "Romania",
		isoCode2: "RO",
		isoCode3: "ROU",
		phonePrefix: "+40",
	},
	{
		id: 179,
		name: "Russia",
		isoCode2: "RU",
		isoCode3: "RUS",
		phonePrefix: "+7",
	},
	{
		id: 180,
		name: "Rwanda",
		isoCode2: "RW",
		isoCode3: "RWA",
		phonePrefix: "+250",
	},
	{
		id: 181,
		name: "Réunion",
		isoCode2: "RE",
		isoCode3: "REU",
		phonePrefix: "+262",
	},
	{
		id: 182,
		name: "Saint Barthélemy",
		isoCode2: "BL",
		isoCode3: "BLM",
		phonePrefix: "+590",
	},
	{
		id: 183,
		name: "Saint Helena",
		isoCode2: "SH",
		isoCode3: "SHN",
		phonePrefix: "+290",
	},
	{
		id: 184,
		name: "Saint Kitts & Nevis",
		isoCode2: "KN",
		isoCode3: "KNA",
		phonePrefix: "+869",
	},
	{
		id: 185,
		name: "Saint Lucia",
		isoCode2: "LC",
		isoCode3: "LCA",
		phonePrefix: "+758",
	},
	{
		id: 186,
		name: "Saint Martin",
		isoCode2: "MF",
		isoCode3: "MAF",
		phonePrefix: "+590",
	},
	{
		id: 187,
		name: "Saint Pierre and Miquelon",
		isoCode2: "PM",
		isoCode3: "SPM",
		phonePrefix: "+508",
	},
	{
		id: 188,
		name: "Saint Vincent & the Grenadines",
		isoCode2: "VC",
		isoCode3: "VCT",
		phonePrefix: "+784",
	},
	{
		id: 189,
		name: "Samoa",
		isoCode2: "WS",
		isoCode3: "WSM",
		phonePrefix: "+685",
	},
	{
		id: 190,
		name: "San Marino",
		isoCode2: "SM",
		isoCode3: "SMR",
		phonePrefix: "+378",
	},
	{
		id: 191,
		name: "Sao Tome & Principe",
		isoCode2: "ST",
		isoCode3: "STP",
		phonePrefix: "+239",
	},
	{
		id: 192,
		name: "Saudi Arabia",
		isoCode2: "SA",
		isoCode3: "SAU",
		phonePrefix: "+966",
	},
	{
		id: 193,
		name: "Senegal",
		isoCode2: "SN",
		isoCode3: "SEN",
		phonePrefix: "+221",
	},
	{
		id: 194,
		name: "Serbia",
		isoCode2: "RS",
		isoCode3: "SRB",
		phonePrefix: "+381",
	},
	{
		id: 195,
		name: "Seychelles",
		isoCode2: "SC",
		isoCode3: "SYC",
		phonePrefix: "+248",
	},
	{
		id: 196,
		name: "Sierra Leone",
		isoCode2: "SL",
		isoCode3: "SLE",
		phonePrefix: "+232",
	},
	{
		id: 197,
		name: "Singapore",
		isoCode2: "SG",
		isoCode3: "SGP",
		phonePrefix: "+65",
	},
	{
		id: 198,
		name: "Sint Maarten",
		isoCode2: "SX",
		isoCode3: "SXM",
		phonePrefix: "+599",
	},
	{
		id: 199,
		name: "Slovakia",
		isoCode2: "SK",
		isoCode3: "SVK",
		phonePrefix: "+421",
	},
	{
		id: 200,
		name: "Slovenia",
		isoCode2: "SI",
		isoCode3: "SVN",
		phonePrefix: "+386",
	},
	{
		id: 201,
		name: "Solomon Islands",
		isoCode2: "SB",
		isoCode3: "SLB",
		phonePrefix: "+677",
	},
	{
		id: 202,
		name: "Somalia",
		isoCode2: "SO",
		isoCode3: "SOM",
		phonePrefix: "+252",
	},
	{
		id: 203,
		name: "South Africa",
		isoCode2: "ZA",
		isoCode3: "ZAF",
		phonePrefix: "+27",
	},
	{
		id: 204,
		name: "South Georgia & SS Islands",
		isoCode2: "GS",
		isoCode3: "SGS",
		phonePrefix: "+500",
	},
	{
		id: 205,
		name: "South Korea",
		isoCode2: "KR",
		isoCode3: "KOR",
		phonePrefix: "+82",
	},
	{
		id: 206,
		name: "South Sudan",
		isoCode2: "SS",
		isoCode3: "SSD",
		phonePrefix: "+211",
	},
	{
		id: 207,
		name: "Spain",
		isoCode2: "ES",
		isoCode3: "ESP",
		phonePrefix: "+34",
	},
	{
		id: 208,
		name: "Sri Lanka",
		isoCode2: "LK",
		isoCode3: "LKA",
		phonePrefix: "+94",
	},
	{
		id: 209,
		name: "Sudan",
		isoCode2: "SD",
		isoCode3: "SDN",
		phonePrefix: "+249",
	},
	{
		id: 210,
		name: "Suriname",
		isoCode2: "SR",
		isoCode3: "SUR",
		phonePrefix: "+597",
	},
	{
		id: 211,
		name: "Svalbard & Jan Mayen",
		isoCode2: "SJ",
		isoCode3: "SJM",
		phonePrefix: "+47",
	},
	{
		id: 212,
		name: "Swaziland",
		isoCode2: "SZ",
		isoCode3: "SWZ",
		phonePrefix: "+268",
	},
	{
		id: 213,
		name: "Sweden",
		isoCode2: "SE",
		isoCode3: "SWE",
		phonePrefix: "+46",
	},
	{
		id: 214,
		name: "Switzerland",
		isoCode2: "CH",
		isoCode3: "CHE",
		phonePrefix: "+41",
	},
	{
		id: 215,
		name: "Syria",
		isoCode2: "SY",
		isoCode3: "SYR",
		phonePrefix: "+963",
	},
	{
		id: 216,
		name: "Taiwan",
		isoCode2: "TW",
		isoCode3: "TWN",
		phonePrefix: "+886",
	},
	{
		id: 217,
		name: "Tajikistan",
		isoCode2: "TJ",
		isoCode3: "TJK",
		phonePrefix: "+992",
	},
	{
		id: 218,
		name: "Tanzania",
		isoCode2: "TZ",
		isoCode3: "TZA",
		phonePrefix: "+255",
	},
	{
		id: 219,
		name: "Thailand",
		isoCode2: "TH",
		isoCode3: "THA",
		phonePrefix: "+66",
	},
	{
		id: 220,
		name: "Timor-Leste",
		isoCode2: "TL",
		isoCode3: "TLS",
		phonePrefix: "+670",
	},
	{
		id: 221,
		name: "Togo",
		isoCode2: "TG",
		isoCode3: "TGO",
		phonePrefix: "+228",
	},
	{
		id: 222,
		name: "Tokelau",
		isoCode2: "TK",
		isoCode3: "TKL",
		phonePrefix: "+690",
	},
	{
		id: 223,
		name: "Tonga",
		isoCode2: "TO",
		isoCode3: "TON",
		phonePrefix: "+676",
	},
	{
		id: 224,
		name: "Trinidad and Tobago",
		isoCode2: "TT",
		isoCode3: "TTO",
		phonePrefix: "+868",
	},
	{
		id: 225,
		name: "Tunisia",
		isoCode2: "TN",
		isoCode3: "TUN",
		phonePrefix: "+216",
	},
	{
		id: 226,
		name: "Turkey",
		isoCode2: "TR",
		isoCode3: "TUR",
		phonePrefix: "+90",
	},
	{
		id: 227,
		name: "Turkmenistan",
		isoCode2: "TM",
		isoCode3: "TKM",
		phonePrefix: "+993",
	},
	{
		id: 228,
		name: "Turks & Caicos Islands",
		isoCode2: "TC",
		isoCode3: "TCA",
		phonePrefix: "+649",
	},
	{
		id: 229,
		name: "Tuvalu",
		isoCode2: "TV",
		isoCode3: "TUV",
		phonePrefix: "+688",
	},
	{
		id: 230,
		name: "Uganda",
		isoCode2: "UG",
		isoCode3: "UGA",
		phonePrefix: "+256",
	},
	{
		id: 231,
		name: "Ukraine",
		isoCode2: "UA",
		isoCode3: "UKR",
		phonePrefix: "+380",
	},
	{
		id: 232,
		name: "United Arab Emirates",
		isoCode2: "AE",
		isoCode3: "ARE",
		phonePrefix: "+971",
	},
	{
		id: 233,
		name: "United Kingdom",
		isoCode2: "GB",
		isoCode3: "GBR",
		phonePrefix: "+44",
	},
	{
		id: 234,
		name: "United States of America",
		isoCode2: "US",
		isoCode3: "USA",
		phonePrefix: "+1",
	},
	{
		id: 235,
		name: "US Minor Outlying Islands",
		isoCode2: "UM",
		isoCode3: "UMI",
		phonePrefix: "+1",
	},
	{
		id: 236,
		name: "Uruguay",
		isoCode2: "UY",
		isoCode3: "URY",
		phonePrefix: "+598",
	},
	{
		id: 237,
		name: "Uzbekistan",
		isoCode2: "UZ",
		isoCode3: "UZB",
		phonePrefix: "+998",
	},
	{
		id: 238,
		name: "Vanuatu",
		isoCode2: "VU",
		isoCode3: "VUT",
		phonePrefix: "+678",
	},
	{
		id: 239,
		name: "Vatican",
		isoCode2: "VA",
		isoCode3: "VAT",
		phonePrefix: "+379",
	},
	{
		id: 240,
		name: "Venezuela",
		isoCode2: "VE",
		isoCode3: "VEN",
		phonePrefix: "+58",
	},
	{
		id: 241,
		name: "Vietnam",
		isoCode2: "VN",
		isoCode3: "VNM",
		phonePrefix: "+84",
	},
	{
		id: 242,
		name: "Virgin Islands (British)",
		isoCode2: "VG",
		isoCode3: "VGB",
		phonePrefix: "+1",
	},
	{
		id: 243,
		name: "Virgin Islands (U.S.)",
		isoCode2: "VI",
		isoCode3: "VIR",
		phonePrefix: "+1",
	},
	{
		id: 244,
		name: "Wallis & Futuna",
		isoCode2: "WF",
		isoCode3: "WLF",
		phonePrefix: "+681",
	},
	{
		id: 245,
		name: "Western Sahara",
		isoCode2: "EH",
		isoCode3: "ESH",
		phonePrefix: "+212",
	},
	{
		id: 246,
		name: "Yemen",
		isoCode2: "YE",
		isoCode3: "YEM",
		phonePrefix: "+967",
	},
	{
		id: 247,
		name: "Zambia",
		isoCode2: "ZM",
		isoCode3: "ZMB",
		phonePrefix: "+260",
	},
	{
		id: 248,
		name: "Zimbabwe",
		isoCode2: "ZW",
		isoCode3: "ZWE",
		phonePrefix: "+263",
	},
	{
		id: 249,
		name: "Åland Islands",
		isoCode2: "AX",
		isoCode3: "ALA",
		phonePrefix: "+358",
	},
];
