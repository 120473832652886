import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
// import { BrowserModule } from '@angular/platform-browser'

import { IonicModule } from '@ionic/angular';
import { HttpClient } from "@angular/common/http";
import { HttpLoaderFactory } from "@/_services/http-loader-factory";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";

import { DeviceTypeModal } from '@/_modals/device-type-modal/device-type-modal';

@NgModule({
	imports: [
		// BrowserModule,
		CommonModule,
		FormsModule,
		IonicModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
	],
	declarations: [ DeviceTypeModal ]
})
export class DeviceTypeModalModule {}
