import { AuthService } from "../_services/auth.service";
import { Injectable } from "@angular/core";
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(private authService: AuthService) {}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((err) => {
				// console.log('errorIntercept:', err);
				if (err.status === 401) {
					this.authService.logout();
					location.reload();
				}
				const error = err.error.message || err.statusText;
				return throwError(error);
			})
		);
	}
}
