import { Client } from './client';
import { Group } from './group';
import { Location } from './location';

export const shortDateFormats = [
	'MMM d, y', 
	'd MMM y', 
	'MMM do, y', 
	'do MMM y'
];

export const longDateFormats = [
	'EEE, d MMM y', 
	'EEE, do MMM y', 
	'EEEE, d MMMM, y', 
	'EEEE, do MMMM, y', 
];

export const timeFormats = [
	'HH:mm', 
	'h:mm aaaaa\'m\'', 
	'h:mm a',
];

export const languageOptions = [
	{name: 'English', code: 'en'}, 
	{name: 'French', code: 'fr'}, 
	{name: 'Spanish', code: 'es'}
];

export const accessMode: any = {
	QRCODE: 'qrcode',
	PINCODE: 'pincode',
	DETAIL: 'detail',
	DELIVERY: 'delivery'
}

export const tagUsage: any = {
	SCAN: 'scan',
	INPUT: 'input',
}

export interface Preference {
	timeFormat: string;
	longDateFormat: string;
	shortDateFormat: string;
	displayLanguage: string;
	receiveBookingEmail: boolean;
	receiveArrivalEmail: boolean;
	approveGuest: boolean;
}

export interface Permission {
	signIn: boolean;
	bookGuest: boolean;
	manageTags: boolean;
	manageUsers: boolean;
	manageGroups: boolean;
	manageSpaces: boolean;
	approveSpaces: boolean;
	reserveSpaces: boolean;
	manageOwnLocation: boolean;
	manageAllLocations: boolean;
	manageBilling: boolean;
	manageAccount: boolean;
	viewLogs: boolean;
}

export class CurrentUser {
	id: number;
	userId: string;
	initials: string;
	firstName: string;
	lastName: string;
	photo: string;
	phonePrefix: string;
	phone: string;
	email: string;
	group: Group;
	location: Location;
	client: Client;
	phonePrefixId: number;
	permission: Permission;
	preference: Preference;
	selectedLocation: Location;
	countryOptions: any[];
	token: string;
	
	constructor(
		id: number,
		userId: string,
		firstName: string,
		lastName: string,
		photo: string,
		phonePrefix: string,
		phone: string,
		email: string,
		group: Group,
		location: Location,
		client: Client,
		phonePrefixId: number,
		permission: Permission,
		preference: Preference,
		
	) {
		this.id = id;
		this.userId = userId;
		this.firstName = firstName;
		this.lastName = lastName;
		this.photo = photo;
		this.phonePrefix = phonePrefix;
		this.phone = phone;
		this.email = email;
		this.group = group;
		this.location = location;
		this.client = client;
		this.phonePrefixId = phonePrefixId;
		this.permission = permission;
		this.preference = preference;
	}
	
	static create() {
		return Object.create(this.prototype);
	}
	
	public get fullName() {
		return this.firstName + ' ' + this.lastName;
	}
	
}
