import { Injectable } from "@angular/core";
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpResponse,
	HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { first, map } from "rxjs/operators";
import { AuthService, StorageService } from "@/_services";

@Injectable({ providedIn: 'root' })
export class JwtInterceptor implements HttpInterceptor {
	constructor(
		private auth: AuthService,
		private storage: StorageService
	) {}

	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		// add authorization header with jwt token if available
		if (
			this.auth.isAuthenticated 
			&& request.url.includes("http") 
			&& !request.url.includes("qrdata")) {

			const token = this.auth.token.value;
			request = request.clone({
				setHeaders: {
					Token: `EONII ${token}`,
					Channel: `console`,
				},
			});
		}
		return next.handle(request).pipe(
			map((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse && event.status === 205) {
					// console.log('resetreq', event);
					this.auth.resetCurrentUser()
						.pipe(first())
						.subscribe(
							async (response) => {
								await this.storage.setCurrentUser(response.currentUser).then(() => {
									// console.log('stored?');
									this.auth.currentUser.next(response.currentUser);
									this.auth.token.next(response.currentUser.token);
								})
								// localStorage.setItem(
								// 	'serverTimestamp', 
								// 	response.serverTimestamp
								// );
							},
							(error) => {
								console.log(error);
							}
						);
				} else {
					// console.log('req', request.url);
					return event;
				}
			})
		);
	}
}
