import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../_services/auth.service';
import { filter, map, take } from 'rxjs/operators';
 
@Injectable({
	providedIn: 'root'
})
export class SettingsGuard implements CanLoad {
 
	constructor(
		private router: Router,
		private authService: AuthService,
	) { }
 
	canLoad(): Observable<boolean> {    
		return this.authService.canViewSettings.pipe(
			filter(val => val !== null), // Filter out initial Behaviour subject value
			take(1), // Otherwise the Observable doesn't complete!
			map(canViewSettings => {
				if (canViewSettings) {
					return true;
				} else {          
					this.router.navigateByUrl('/', { replaceUrl: true });
					return false;
				}
			})
		);
	}

}