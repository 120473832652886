import { environment } from "../../environments/environment";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, timeout, catchError, tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { take } from "rxjs/operators";
import { 
	StorageService, 
	TOKEN, 
	CURRENT_USER, 
	SERVER_TIMESTAMP, 
	LocalDataset
} from "./storage.service";
import { CurrentUser, DeliveryLog, Guest, GuestLog, DeliverySender, SpaceLog, TenantLocation, User, Tag } from "@/_models";
import { AuthService } from "./auth.service";

@Injectable({
	providedIn: 'root'
})
export class AppDataService {

	guestlogsLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
	isLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
	
	// Data Subjects
	guestLogs: BehaviorSubject<GuestLog[]> = new BehaviorSubject<GuestLog[]>([]);
	deliveryLogs: BehaviorSubject<DeliveryLog[]> = new BehaviorSubject<DeliveryLog[]>([]);
	spaceLogs: BehaviorSubject<SpaceLog[]> = new BehaviorSubject<SpaceLog[]>([]);
	tenants: BehaviorSubject<TenantLocation[]> = new BehaviorSubject<TenantLocation[]>([]);
	senders: BehaviorSubject<DeliverySender[]> = new BehaviorSubject<DeliverySender[]>([]);
	guests: BehaviorSubject<Guest[]> = new BehaviorSubject<Guest[]>([]);
	users: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
	tags: BehaviorSubject<Tag[]> = new BehaviorSubject<Tag[]>([]);

	datasets: any = [
		'guestLogs', 
		'deliveryLogs', 
		'spaceLogs', 
		'tenants', 
		'senders',
		'guests', 
		'users', 
		'tags' 
	]

	constructor(
		private auth: AuthService,
		private storage: StorageService,
	) {
		// console.log('init appDataService');
		this.loadAppData(this.datasets); // for refreshers
	}


	setAppData(dataset?: any) {
		// console.log('==============setAppData=============');
		// console.log('setAppData', dataset);
		dataset.map((el: any) => {
			// console.log('set ' + el.name, this[el.name].value);
			this[el.name].next(el.data);
		});
	}
	
	unsetAppData() {
		this.isLoaded.next(null);
		this.guestlogsLoaded.next(null);
		this.datasets.map((el: any) => {
			this[el].next([]);
		});
	}

	async loadAppData(subjects: any[]) {
		// console.log('start loadAppData ==============>');
		const loadOp = subjects.map(async (el: any) => {
			await this.storage.getObject(el).then((data) => {
				this[el].next(data);
				if (el === 'guestLogs') {
					this.guestlogsLoaded.next(true);
				}
			});
		});
		await Promise.all(loadOp).then(() => {
			this.isLoaded.next(true);
			// console.log('end loadAppData ==============>');			
		});
	}

	async syncData() {
		this.auth
			.syncAppData({ 
				timestamp: this.auth.serverTimestamp.value,
				locationId: this.auth.currentUser.value.selectedLocation.id
			 })
			.pipe(take(1))
			.subscribe((res) => {
				// console.log('appDataSync: ', res.dataset);
				this.storage.syncStoredData(res.dataset, res.serverTimestamp).then(() => {
					this.auth.serverTimestamp.next(res.serverTimestamp);
					this.syncAppData(res.dataset);
					// console.log('syncAppData');
				});
			});
	}

	syncAppData(dataset?: any) {
		dataset.map((item: any) => {
			if (item.data.length > 0) {
				this.storage.getObject(item.name).then((data) => {
					this[item.name].next(data);
					// console.log('synced ' + el.name, this[el.name].value);
				})
			}
		});
	}

	getGuestLogs(): Observable<GuestLog[]> {
		return this.guestLogs.asObservable();
	}

	getGuests(): Observable<Guest[]> {
		return this.guests.asObservable();
	}

	getUsers(): Observable<User[]> {
		return this.users.asObservable();
	}

	getTags(): Observable<Tag[]> {
		return this.tags.asObservable();
	}

	getDeliveryLogs(): Observable<DeliveryLog[]> {
		return this.deliveryLogs.asObservable();
	}

	getSpaceLogs(): Observable<SpaceLog[]> {
		return this.spaceLogs.asObservable();
	}

}
