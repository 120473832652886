import { environment } from "../../../environments/environment";
import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { IonSearchbar, IonTabBar, IonTabButton, IonIcon, IonLabel, IonBadge } from '@ionic/angular';
import { ModalController } from "@ionic/angular";
// import { GuestlogModal } from "@/_modals/guestlog-modal/guestlog-modal";
import { AuthService, StorageService } from '@/_services';
import { CurrentUser, User } from '@/_models';

@Component({
	selector: 'app-select-user-modal',
	templateUrl: './select-user-modal.html',
	styleUrls: ['./select-user-modal.scss'],
	providers : [ TranslateService ]

})
export class SelectUserModal implements OnInit {
	@ViewChild('searchContainer', { static: true }) searchContainer: ElementRef;
	@ViewChild('searchbar', { static: true }) searchbar: IonSearchbar;

	BASE_URL = environment.base_url;
	currentUser: CurrentUser;

	users: any[] = [];
	filteredUsers: User[] = [];
	searchString: string = '';

	@HostListener("window:keyboardWillShow", ["$event"])
	keyboardWillShow(event?: Event) {
	  console.log("Keyboard will Show");
	}
	@HostListener("window:keyboardDidShow", ["$event"])
	keyboardDidShow(event?: Event) {
	  console.log("Keyboard did Show");
	}
	
	constructor(
		private auth: AuthService,
		public viewCtrl: ModalController,
		private storage: StorageService,
		private translateService: TranslateService
	) { 
		this.currentUser = this.auth.currentUser.value;
		this.translateService.setDefaultLang(this.currentUser.preference.displayLanguage);
	}

	ngOnInit() {
		this.storage.getUsers().then((users) => {
			const userSubset = users.filter((user) => {
				return user.status === User.STATUS_ENABLED
					|| user.status === User.STATUS_ACTIVATION_PENDING;
			})
			this.users = userSubset;
			this.filteredUsers = userSubset;
		});
	}
	setFilteredUsers() {
		this.filteredUsers = this.filterUsers(this.searchString);
		// console.log('searchContainer Search => ', this.searchContainer.nativeElement.offsetHeight);
	}
	filterUsers(searchString: any) {
		return this.users.filter(user => {
			return (
				user.fullName.toLowerCase().startsWith(searchString.toLowerCase()) || // fisrt name
				user.fullName.toLowerCase().includes(' ' + searchString.toLowerCase()) || // other names
				user.location.client.name.toLowerCase().startsWith(searchString.toLowerCase()) || // tenant names
				user.location.client.name.toLowerCase().includes(' ' + searchString.toLowerCase()) // tenant names
			)
		});
	}
	async selectUser(user?: User) {
		this.dismiss(user);
	}
	dismiss(user?: User) {
		this.viewCtrl.dismiss(user);
	}
	
}
