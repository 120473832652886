export const GuestLogAction = {
	INVITE: "invite",
	UPDATE: "update",
	DELETE: "delete",
	REMOVE: "remove",
	ARRIVED: "arrived",
	APPROVE: "approve",
	DECLINE: "decline",
	SIGN_IN: "sign-in",
	SIGN_OUT: "sign-out",
};
export interface GuestDevice {
	type: string;
	description: string;
	serialNo: string;
}
export class GuestLog {
	public static STATUS = {
		SIGNED_IN: 'SI',
		SIGNED_OUT: 'SO',
		NOT_ARRIVED: "NA",
		APPROVAL_PENDING: 'AP',
		APPROVAL_GRANTED: 'AG',
		APPROVAL_DECLINED: 'AD',
		DELETED: 'DL'
	}
	public static hostType = {
		USER: "U",
		GROUP: "G"
	}
	public static logType = {
		BOOKING: "BK",
		WALK_IN: "WI",
		EXTRA: "EX"
	}
	// public static TYPE_BOOKING = "BK";
	// public static TYPE_WALK_IN = "WI";
	// public static TYPE_EXTRA = "EX";

	id: any;
	hostId: any;
	hostType: string;
	hostInitials: string;
	hostName: string;
	creationDate: any;
	sortDate: any;
	guestId: string;
	guestInitials: string;
	guestName: string;
	guestPhone: string;
	guestEmail: string;
	guestAddress: string;
	guestPhoto: string;
	bookingPin: string;
	expectedTime: string;
	arrivalTime: string;
	approvalTime: string;
	timeIn: string;
	timeOut: string;
	tagNumber: string;
	status: string;
	logType: string;
	hasSeen: boolean;
	isPrivate: boolean;
	requireApproval: boolean;
	serviceRating: any;
	creatorId: string;
	receiverId: string;
	receiverName: string;
	releaserId: string;
	releaserName: string;
	locationId: number;
	subLocationId: number;
	clientId: number;
	lastModified: string;
	expectedTimestamp: any;
	arrivalTimestamp: any;
	approvalTimestamp: any;
	expectedOutTimestamp: any;
	inTimestamp: any;
	outTimestamp: any;
	modTimestamp: any;
	userIsHost: boolean;
	qrData: any;
	guestDevices: any;

	constructor(
		id: any,
		hostId: any,
		hostType: string,
		hostName: string,
		creationDate: any,
		sortDate: any,
		guestId: string,
		guestName: string,
		guestPhone: string,
		guestEmail: string,
		guestAddress: string,
		guestPhoto: string,
		bookingPin: string,
		expectedTime: string,
		arrivalTime: string,
		approvalTime: string,
		timeIn: string,
		timeOut: string,
		tagNumber: string,
		status: string,
		logType: string,
		hasSeen: boolean,
		isPrivate: boolean,
		requireApproval: boolean,
		receiverId: string,
		locationId: number,
		subLocationId: number,
		clientId: number,
		lastModified: string
	) {
		this.id = id;
		this.hostId = hostId;
		this.hostType = hostType;
		this.hostName = hostName;
		this.creationDate = creationDate;
		this.sortDate = sortDate;
		this.guestId = guestId;
		this.guestName = guestName;
		this.guestPhone = guestPhone;
		this.guestEmail = guestEmail;
		this.guestAddress = guestAddress;
		this.guestPhoto = guestPhoto;
		this.bookingPin = bookingPin;
		this.expectedTime = expectedTime;
		this.arrivalTime = arrivalTime;
		this.approvalTime = approvalTime;
		this.timeIn = timeIn;
		this.timeOut = timeOut;
		this.tagNumber = tagNumber;
		this.status = status;
		this.logType = logType;
		this.hasSeen = hasSeen;
		this.isPrivate = isPrivate;
		this.requireApproval = requireApproval;
		this.receiverId = receiverId;
		this.locationId = locationId;
		this.subLocationId = subLocationId;
		this.clientId = clientId;
		this.lastModified = lastModified;
	}
}
