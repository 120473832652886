import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
// import { App } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';

@Component({
  selector: 'app-root',
  templateUrl: 'app.html',
  styleUrls: ['app.scss'],
})
export class AppComponent {
	constructor(
		private platform: Platform,
	) {
		this.initializeApp();
		this.platform.backButton.subscribe(() => {
			console.log("BACK BUTTON CALLED");
		});
	}

  initializeApp() {
	// App.addListener('appStateChange', (state) => {
	// 	console.log('===>>> appStateChange ==>>', JSON.stringify(state))
	// });
	// App.addListener('appRestoredResult', data => {
	// 	console.log('===>>> Restored state ==>> ', JSON.stringify(data));
	// });
	this.platform.ready().then(() => {
		const setStatusBarStyleLight = async () => {
			StatusBar.setStyle({ style: Style.Light });
		};
		// const setStatusBarBackgroundColor = async () => {
		// 	StatusBar.setBackgroundColor({ color: '#ffffff' });
		// };
		if (this.platform.is('android')) {
			StatusBar.setStyle({ style: Style.Light });
			StatusBar.setOverlaysWebView({ overlay: false });
			StatusBar.setBackgroundColor({ color: '#ffffff' });
			// StatusBar.hide();
		}
		SplashScreen.hide();
	});
}

}
