import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { HttpLoaderFactory } from "@/_services/http-loader-factory";

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app';
import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor, ErrorInterceptor } from "@/_guards";
import { AppMenu } from '@/_shared/utils'
import { DeviceTypeModalModule } from '@/_modals/device-type-modal/device-type-modal.module';
import { SelectCountryCodeModalModule } from '@/_modals/select-country-code-modal/select-country-code-modal.module';
import { SelectGuestLogModalModule } from '@/_modals/select-guestlog-modal/select-guestlog-modal.module';
import { SelectUserModalModule } from '@/_modals/select-user-modal/select-user-modal.module';


@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		DeviceTypeModalModule,
		SelectCountryCodeModalModule,
		SelectGuestLogModalModule,
		SelectUserModalModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		IonicModule.forRoot(),
		AppRoutingModule
	],
	providers: [
		AppMenu,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
