
import { ModalController, PopoverController } from '@ionic/angular';
import { Injectable } from "@angular/core";
import { AppMenuModule } from '@/_shared/modules';
import { User, SpaceLog } from '@/_models';
// import { GuestLog } from '@/_models/guestlog';
// import { SpaceLog } from '@/_models/spacelog';
import { format, formatDistance, parseISO } from 'date-fns';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
const phoneFormat = PhoneNumberFormat;

export const isObject = (object: any): boolean => {
	return typeof object === 'object' 
		&& !Array.isArray(object) 
		&& object !== null;
};
export const microTime = (date: any): any => {
	return new Date(date).getTime();
};
export const formatDateAgo = (sqlDateTime: Date): any => {
	const agoSuffix: boolean = Math.abs(((new Date(sqlDateTime).getTime() - new Date().getTime()) / 1000)) > 60;
	return formatDistance(
		new Date(sqlDateTime),
		new Date(),
		{ addSuffix: agoSuffix }
	)
	.replace('about ', '')
	.replace('less than a minute', 'Just now')
};
export const formatDateTime = (sqlDateTime: any, pattern: string): any => {
	return format(new Date(sqlDateTime), pattern);
}
export const nameInitials = (name: any): any => {
	return name.trim().split(' ').length === 1 
	? name
		.replace(/[^A-Za-z0-9À-ÿ ]/ig, '')        // taking care of accented characters as well
		.substr(0, 2)
		.toUpperCase()
	: name
		.replace(/[^A-Za-z0-9À-ÿ ]/ig, '')        // taking care of accented characters as well
		.replace(/ +/ig, ' ')                     // replace multiple spaces to one
		.split(/ /)                               // break the name into parts
		.reduce((acc, item) => acc + item[0], '') // assemble an abbreviation from the parts
		.concat(name.substr(1))                   // what if the name consist only one part
		.concat(name)                             // what if the name is only one character
		.substr(0, 2)                             // get the first two characters an initials
		.toUpperCase();                           // uppercase, but you can format it with CSS as well
};
export const asHost = (currentUser: any): User => {
	return new User(
		currentUser.id,
		currentUser.userId,
		currentUser.firstName,
		currentUser.lastName,
		currentUser.fullName,
		currentUser.email,
		currentUser.phonePrefix,
		currentUser.phone,
		currentUser.photo,
		User.STATUS_ENABLED,
		currentUser.group !== null 
			? currentUser.group.id 
			: null,
		currentUser.location.id,
		currentUser.client.id,
		currentUser.permission,
		currentUser.phonePrefixId,
		currentUser.preference.approveGuest
	);
}
export const statusString = (
	log: any, 
	serverTimestamp?: number, 
	expirySetting?: number
): any => {
	switch (log.status) {
		case 'NA': {
			if (serverTimestamp !== undefined
				&& expirySetting !== undefined
				&&  ((log.expectedTimestamp + expirySetting) < serverTimestamp)) {
				return 'Expired'
			}
			return 'Invited'
		}
		case 'SI': {
			return 'Signed In'
		}
		case 'AP': {
			return typeIsSpacelog(log) 
				&& log.endTimestamp < serverTimestamp 
					? 'Reservation' 
					: 'Approval Pending'
		}
		case 'AG': {
			return typeIsSpacelog(log) 
				&& log.endTimestamp < serverTimestamp 
					? 'Reservation' 
					: 'Guest Approved'
		}
		case 'AD': {
			return typeIsSpacelog(log) 
				&& log.endTimestamp < serverTimestamp 
					? 'Reservation' 
					: 'Declined'
		}
		case 'CO': {
			return typeIsSpacelog(log) 
				&& log.endTimestamp < serverTimestamp 
					? 'Reservation' 
					: 'Reserved'
		}
		case 'SO': {
			return 'Signed Out'
		}
		case 'RC': {
			return 'Received'
		}
		default: {
			return 'Guestlog'
		}
	}
};
export const statusClass = (
	log: any, 
	serverTimestamp?: number, 
	expirySetting?: number
): any => {
	switch (log.status) {
		case 'NA': {
			if (serverTimestamp !== undefined
				&& expirySetting !== undefined
				&&  ((log.expectedTimestamp + expirySetting) < serverTimestamp)) {
				return 'danger'
			}
			return 'info'
		}
		case 'SI': {
			return 'success';
		}
		case 'AP': {
			return typeIsSpacelog(log) 
				&& log.endTimestamp < serverTimestamp 
					? '' 
					: 'warning';
		}
		case 'AG': {
			return typeIsSpacelog(log) 
				? (log.endTimestamp < serverTimestamp ? '' : 'success')
				: 'warning';
		}
		case 'AD': {
			return typeIsSpacelog(log) 
				&& log.endTimestamp < serverTimestamp 
					? '' 
					: 'danger';
		}
		case 'CO': {
			return typeIsSpacelog(log) 
				? (log.endTimestamp < serverTimestamp ? '' : 'info')
				: 'info';
		}
		case 'SO':
		case 'RC': {
			return '';
		}
		default: {
			return '';
		}
	}
};
export const transformAppData = (dataset: any): any => {
	const data = {};
	dataset.map((el) => {
		data[el.name] = el.data;
	});
	return data;
};
export const sqlDate = (date?: Date): string => {
	const sqlDate = date !== undefined ? date : new Date();
	return sqlDate.getFullYear() + "-" +
		('0' + (sqlDate.getMonth() + 1)).substr(-2) + "-" +
		('0' + (sqlDate.getDate())).substr(-2);
};
export const sqlDateTime = (date?: Date): string => {
	const sqlDateTime = date !== undefined ? date : new Date();
	return sqlDateTime.getFullYear() + "-" +
		('0' + (sqlDateTime.getMonth() + 1)).substr(-2) + "-" +
		('0' + (sqlDateTime.getDate())).substr(-2) + " " +
		('0' + (sqlDateTime.getHours())).substr(-2) + ":" +
		('0' + (sqlDateTime.getMinutes())).substr(-2) + ":" +
		('0' + (sqlDateTime.getSeconds())).substr(-2);
	// return date !== undefined
	// 	? date.getFullYear() + "-" +
	// 		('0' + (date.getMonth() + 1)).substr(-2) + "-" +
	// 		('0' + (date.getDate())).substr(-2) + " " +
	// 		('0' + (date.getHours())).substr(-2) + ":" +
	// 		('0' + (date.getMinutes())).substr(-2) + ":" +
	// 		('0' + (date.getSeconds())).substr(-2)
	// 	: new Date().getFullYear() + "-" +
	// 		('0' + (new Date().getMonth() + 1)).substr(-2) + "-" +
	// 		('0' + (new Date().getDate())).substr(-2) + " " +
	// 		('0' + (new Date().getHours())).substr(-2) + ":" +
	// 		('0' + (new Date().getMinutes())).substr(-2) + ":" +
	// 		('0' + (new Date().getSeconds())).substr(-2);
};
export const bookingDate = (date?: Date): Date => {
	const dateo = new Date();
	if (dateo.getMinutes() > 45) {
		dateo.setHours(dateo.getHours() + 1);
		dateo.setMinutes(0);
	} else {
		dateo.setMinutes(Math.ceil(dateo.getMinutes() / 15) * 15);
	}
	dateo.setSeconds(0)
	return dateo;
}
export const ionDateTimeFormat = (date?: Date): string => {
	const ionDateTime = date !== undefined ? date : bookingDate();
	return ionDateTime.getFullYear() + "-" 
		+ ('0' + (ionDateTime.getMonth() + 1)).substr(-2) + "-" 
		+ ('0' + (ionDateTime.getDate())).substr(-2) + "T" 
		+ ('0' + (ionDateTime.getHours())).substr(-2) + ":" 
		+ ('0' + (ionDateTime.getMinutes())).substr(-2) + ":" 
		+ ('0' + (ionDateTime.getSeconds())).substr(-2) 
		+ offsetString(ionDateTime.getTimezoneOffset());
};
function offsetString(offsetMins: any) {
	const rhours = (Math.abs(offsetMins) / 60);
	const hours = Math.floor(rhours);
	const minutes = Math.round((rhours - hours) * 60);
	return (offsetMins < 0 ? "+" : "-")
		+ ('0' + (rhours)).substr(-2) + ":" 
		+ ('0' + (minutes)).substr(-2);
}
export const formatPhoneNumber = (number: any, regionCode?: any): any => {
	const phone = phoneUtil.parseAndKeepRawInput(number, regionCode);
	const source = phone.getCountryCodeSource();

	if (
		(source === 1		// FROM_NUMBER_WITH_PLUS_SIGN = 1
		|| source === 5) 	// FROM_NUMBER_WITH_IDD = 5
		&& phoneUtil.isPossibleNumber(phone)
		&& phoneUtil.isValidNumber(phone)
	) {
		return { 
			valid: true, 
			countryCode: phoneUtil.getRegionCodeForNumber(phone), 
			number: phoneUtil.format(phone, phoneFormat.E164), 
			national: phone.getNationalNumber(),
			formatted: phoneUtil.format(phone, phoneFormat.INTERNATIONAL) 
		};
	} else {
		//source ===  UNSPECIFIED = 0; FROM_NUMBER_WITHOUT_PLUS_SIGN = 10; FROM_DEFAULT_COUNTRY = 20
		switch(false) {
			case phoneUtil.isValidNumberForRegion(phone, regionCode):
				return { 
					valid: false, 
					national: phone.getNationalNumber(),
					error: 'Invalid number for +' + phone.getCountryCode() + ' Region' 
				};
			case phoneUtil.isPossibleNumber(phone):
				return { 
					valid: false, 
					national: phone.getNationalNumber(),
					error: 'Unrecognized number format' 
				};
			case phoneUtil.isValidNumber(phone):
				return { 
					valid: false, 
					national: phone.getNationalNumber(),
					error: 'Invalid phone number' 
				};
			default: {
				return { 
					valid: true, 
					countryCode: phoneUtil.getRegionCodeForNumber(phone), 
					number: phoneUtil.format(phone, phoneFormat.E164), 
					national: phone.getNationalNumber(),
					formatted: phoneUtil.format(phone, phoneFormat.INTERNATIONAL) 
				};
			}
		}	
	}
};
export const typeIsSpacelog = (object: any): object is SpaceLog => {
	if ((object as SpaceLog).spaceName) {
		return true;
	}
	return false;
}
export const typeIsUser = (object: any): object is User => {
	if ((object as User).firstName) {
		return true;
	}
	return false;
}
@Injectable()
export class AppMenu {
	constructor(public popoverCtrl: PopoverController) {}
  
	async present(ev?: PointerEvent): Promise<void> {
		const menu = await this.popoverCtrl.create({
			component: AppMenuModule,
			showBackdrop: true,
			cssClass: 'app-menu',
			event: ev,
			translucent: true,
			// componentProps: {
			// 	"person": this.persons[i],
			// }
		});
		return await menu.present();
	}
}
export const minuteIncrement30 = "0, 30";
export const minuteIncrement15 = "0, 15, 30, 45";
export const minuteIncrement5 = "0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55";
export const monthValues = [
	'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
]

// @Injectable()
// export class SelectGuest {
// 	showContacts: boolean = false;
// 	constructor(public modalCtrl: ModalController) {}

// 	async present() {
// 		const selectGuest = await this.modalCtrl.create({
// 			component: SelectGuestModal,
			
// 			componentProps: {
// 				"pageMode": false,
// 				"showContacts": this.showContacts,
// 			}
// 		});
// 		selectGuest.present();
// 	}
  
	// async present(ev?: PointerEvent): Promise<void> {
	// 	const menu = await this.modalCtrl.create({
	// 		component: SelectGuest,
	// 		showBackdrop:false,
	// 		cssClass: 'app-menu',
	// 		event: ev,
	// 		translucent: true,
	// 		componentProps: {
	// 			"person": this.persons[i],
	// 		}
	// 	});
	// 	return await menu.present();
	// }
// }
