import { Client } from "./client";

export const bookingExpiryOptions = [
	{ label: '1 Hour', value: 1}, 
	{ label: '3 Hours', value: 3}, 
	{ label: '6 Hours', value: 6},
	{ label: '12 Hours', value: 12},
	{ label: '24 Hours', value: 24}
];


export const garbageCollectionOptions = [
	{ label: '1 Hour', value: 1}, 
	{ label: '3 Hours', value: 3}, 
	{ label: '6 Hours', value: 6},
	{ label: '12 Hours', value: 12},
	{ label: '24 Hours', value: 24}
];


export const maxLeadDaysOptions = [
	{ label: '1 Day', value: 1}, 
	{ label: '7 Days', value: 7}, 
	{ label: '14 Days', value: 14}, // temp
	{ label: '15 Days', value: 15},
	{ label: '30 Days', value: 30},
];


export const deliveryDestinationOptions = [
	{ label: 'User', value: 'U'}, 
	{ label: 'Mail Room', value: 'MR'}, 
	{ label: 'Front Office', value: 'FD'}
];


export interface Country {
	id: number;
	name: string;
	isoCode2: string;
	isoCode3: string;
	phonePrefix: string;
}

export interface FacilitySettings {
	settingsMaxLeadDays: number;
	settingsBookingExpiry: number;
	settingsGarbageCollection: number;
	settingsDeliveryDestination: string;
	settingsDefaultLanguage: string;
	policyTagsEnabled: boolean;
	policyWalkInEnabled: boolean;
	policyApprovalRequired: boolean;
	policyVerificationRequired: boolean;
	policyGuestFeedback: boolean;
	policyLogUsers: boolean;
}

export interface Space {
	id: number;
	name: string;
	type: string;
	capacity: number;
	isEnabled: boolean;
	requireApproval: boolean;
	locationId: number;
	clientId: number;
	lastModified: string;
}

export const SpaceIcon = {
	GY: 'space-gymnasium',
	MR: 'space-meeting-room',
	RS: 'space-restaurant',
	SP: 'space-swimming-pool',
	TC: 'space-tennis-court',
	TR: 'space-terrace',
	OT: 'space-other',
};
export const SpaceType = {
	GY: 'Gymnasium',
	MR: 'Meeting Room',
	RS: 'Restaurant',
	SP: 'Swimming Pool',
	TC: 'Tennis Court',
	TR: 'Terrace',
	OT: 'Other',
};

export interface TenantLocation {
	id: number;
	name: string;
	country: Country;
	client: Client;
	address: string;
	subAddress: string;
	state: string;
	gps: string;
	isActive: boolean;
	isTenant: boolean;
	isFacility: boolean;
	category: string;
	settingsMaxLeadDays: number;
	settingsBookingExpiry: number;
	settingsGarbageCollection: number;
	settingsDeliveryDestination: string;
	settingsDefaultLanguage: string;
	policyTagsEnabled: boolean;
	policyWalkInEnabled: boolean;
	policyApprovalRequired: boolean;
	policyLogUsers: boolean;
	clientId: boolean;
	countryId: boolean;
}

export interface LocationSetting {
	bookingExpiry: number;
	pinFormat: any;
	logArchiving: number;
	maxLeadDays: number;
	defaultLanguage: string;
}
export interface LocationProcess {
	useTags: boolean;
	logDevices: boolean;
	requireApproval: boolean;
	enableFeedback: boolean;
	deliveryDestination: any;
}
export interface LocationPolicy {
	allowWalkIn: boolean;
	logDeliveries: boolean;
	logUsers: boolean;
	verifyGuests: boolean;
	guestCapacity: number;
}

export class Location {
	public static CATEGORY_CORPORATE = "C";
	public static CATEGORY_RESIDENTIAL = "R";

	id: number;
	name: string;
	country: Country;
	address: string;
	subAddress: string;
	state: string;
	gps: string;
	isActive: boolean;
	isTenant: boolean;
	isFacility: boolean;
	category: string;
	
	setting: LocationSetting;
	process: LocationProcess;
	policy: LocationPolicy;

	settingsMaxLeadDays: number;
	settingsBookingExpiry: number;
	settingsGarbageCollection: number;
	settingsDeliveryDestination: string;
	settingsDefaultLanguage: string;
	settingsPinFormat: string;

	// settingsLogDeliveries: boolean;
	// settingsGuestCapacity: number;
	
	// policyTagsEnabled: boolean;
	// settingsLogGuestDevices: boolean;
	// policyGuestFeedback: boolean;

	// policyWalkInEnabled: boolean;
	// policyApprovalRequired: boolean;
	// policyVerificationRequired: boolean;
	// policyLogUsers: boolean;
	clientId: number;
	facilityId: number;
	countryId: number;
	subscriptionType: string;
	subscriptionStart: string;
	subscriptionEnd: string;
	subscriptionExpired: boolean;
	lastModified: boolean;
	tenants: TenantLocation[];
	userCount: number;
	spaces: Space[];
	facilityCategory: string;
	facilitySettings: FacilitySettings;
	facilitySpaces: Space[];
	facilityLastModified: any;
	isUserLocation: boolean;
	
	constructor(
		id: number,
		name: string,
		country: Country,
		address: string,
		subAddress: string,
		state: string,
		gps: string,
		isActive: boolean,
		isTenant: boolean,
		isFacility: boolean,
		settingsMaxLeadDays: number,
		settingsBookingExpiry: number,
		settingsGarbageCollection: number,
		settingsDeliveryDestination: string,
		settingsDefaultLanguage: string,
		processUserTags: boolean,
		processRequireApproval: boolean,
		processEnableFeedback: boolean,
		policyAllowWalkIn: boolean,
		policyVerifyGuests: boolean,
		policyLogUsers: boolean,
	) {
		this.id = id;
		this.name = name;
		this.country = country;
		this.address = address;
		this.subAddress = subAddress;
		this.state = state;
		this.gps = gps;
		this.isActive = isActive;
		this.isTenant = isTenant;
		this.isFacility = isFacility;
		this.settingsMaxLeadDays = settingsMaxLeadDays;
		this.settingsBookingExpiry = settingsBookingExpiry;
		this.settingsGarbageCollection = settingsGarbageCollection;
		this.settingsDeliveryDestination = settingsDeliveryDestination;
		this.settingsDefaultLanguage = settingsDefaultLanguage;
		this.process.useTags = processUserTags;
		this.process.requireApproval = processRequireApproval;
		this.process.enableFeedback = processEnableFeedback;
		this.policy.allowWalkIn = policyAllowWalkIn;
		this.policy.verifyGuests = policyVerifyGuests;
		this.policy.logUsers = policyLogUsers;
	}
	
}
