export class Tag {
	id: number;
	dateCreated: string;
	tagNumber: string;
	inUse: boolean;
	lastLogId: number;
	lastUsed: string;
	lastUsedType: string;
	lastUsedId: string;
	lastUsedName: string;
	additionalData: string;
	isEnabled: boolean;
	locationId: number;
	clientId: number;
	lastModified: string;
	usedTimestamp: any
	modTimestamp: any
	
	constructor(
		id: number,
		dateCreated: string,
		tagNumber: string,
		inUse: boolean,
		lastLogId: number,
		lastUsed: string,
		lastUsedType: string,
		lastUsedId: string,
		lastUsedName: string,
		additionalData: string,
		isEnabled: boolean,
		locationId: number,
		clientId: number,
		lastModified: string
		) {
		this.id = id;
		this.dateCreated = dateCreated;
		this.tagNumber = tagNumber;
		this.inUse = inUse;
		this.lastLogId = lastLogId;
		this.lastUsed = lastUsed;
		this.lastUsedType = lastUsedType;
		this.lastUsedId = lastUsedId;
		this.lastUsedName = lastUsedName;
		this.additionalData = additionalData;
		this.isEnabled = isEnabled;
		this.locationId = locationId;
		this.clientId = clientId;
		this.lastModified = lastModified;
	}
		
}
