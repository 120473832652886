import { Country, CurrentUser } from '@/_models';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModalController, IonSearchbar } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Animations } from "@/_shared/animations";
import { AuthService } from "@/_services";
import { deviceTypeOptions } from "@/_shared/device-types";


@Component({
	selector: 'app-device-type-modal',
	templateUrl: './device-type-modal.html',
	styleUrls: ['./device-type-modal.scss'],
	animations: [ Animations ]
})
export class DeviceTypeModal implements OnInit {
	@ViewChild('searchContainer', { static: true }) searchContainer: ElementRef;
	@ViewChild('searchbar', { static: true }) searchbar: IonSearchbar;

	currentUser: CurrentUser;
	selectedDeviceTypeId: any;

	deviceTypeOptions = deviceTypeOptions;
	
	selectedDeviceName: any;
	filteredDeviceTypes: any[] = [];
	searchString: string = '';


	constructor(
		public auth: AuthService,
		public viewCtrl: ModalController,
		private translateService: TranslateService
	) {
		this.currentUser = this.auth.currentUser.value;
		this.translateService.setDefaultLang(this.currentUser.preference.displayLanguage);
	}
	ngOnInit() {
		this.filteredDeviceTypes = this.deviceTypeOptions;
	}
	setFilteredDevices() {
		this.filteredDeviceTypes = this.filterDevices(this.searchString);
	}
	filterDevices(searchString: any) {
		return this.deviceTypeOptions.filter(device => {
			return device.name.toLowerCase().includes(searchString.toLowerCase());
		});
	}
	
	async selectDevice(ev: CustomEvent) {
		if (ev.detail.value !== this.selectedDeviceTypeId) {
			const deviceType = this.deviceTypeOptions.find((el) => el.id === parseInt(ev.detail.value));
			// console.log('selCountry', country);
			this.dismiss(deviceType);
		}
	}
	dismiss(countryCode?: any) {
		this.viewCtrl.dismiss(countryCode)
	}
}
