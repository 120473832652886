export const SpaceLogAction = {
	CREATE: "CREATE",
	UPDATE: "UPDATE",
	DELETE: "DELETE",
	APPROVE: "APPROVE",
	DECLINE: "DECLINE",
};

export class SpaceLog {

	public static TYPE_GYNASIUM = "GY";
	public static TYPE_MEETINGROOM = "MR";
	public static TYPE_RESTAURANT = "RS";
	public static TYPE_SWIMMINGPOOL = "SP";
	public static TYPE_TENNISCOURT = "TC";
	public static TYPE_TERRACE = "TR";
	public static TYPE_OTHEWR = "OT";

	public static STATUS_CONFIRMED = 'CO';
    public static STATUS_PENDING = 'AP';
    public static STATUS_APPROVED = 'AG';
    public static STATUS_DECLINED = 'AD';
    public static STATUS_DELETED = 'DL';


	id: any;
	spaceId: any;
	spaceName: string;
	spaceType: string;
	userId: any;
	userName: string;
	userInitials: string;
	userPhoto: string;
	userType: string;
	sortDate: any;
	status: string;
	startTime: string;
	endTime: string;
	comment: string;
	startTimestamp: number;
	endTimestamp: number;
	creatorId: any;
	approverId: any;
	approverName: string;
	approvalTime: string;
	locationId: number;
	clientId: number;
	subLocationId: number;
	relatedGuestLogId: number;
	lastModified: string;

	constructor(
		id: any,
		spaceId: any,
		spaceName: string,
		spaceType: string,
		userId: any,
		userName: string,
		userType: string,
		sortDate: any,
		status: string,
		startTime: string,
		endTime: string,
		comment: string,
		creatorId: any,
		approverId: any,
		approverName: string,
		approvalTime: string,
		locationId: number,
		clientId: number,
		subLocationId: number,
		relatedGuestLogId: number,
		lastModified: string
	) {
		this.id = id;
		this.spaceId = spaceId;
		this.spaceName = spaceName;
		this.spaceType = spaceType;
		this.userId = userId;
		this.userName = userName;
		this.userType = userType;
		this.sortDate = sortDate;
		this.status = status;
		this.startTime = startTime;
		this.endTime = endTime;
		this.comment = comment;
		this.creatorId = creatorId;
		this.approverId = approverId;
		this.approverName = approverName;
		this.approvalTime = approvalTime;
		this.locationId = locationId;
		this.clientId = clientId;
		this.subLocationId = subLocationId;
		this.relatedGuestLogId = relatedGuestLogId;
		this.lastModified = lastModified;
	}
}
