// import { Injectable } from '@angular/core';
// import { CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
// import { Observable } from 'rxjs';

// @Injectable({
//   providedIn: 'root'
// })
// export class AutoLoginGuard implements CanLoad {
//   canLoad(
//     route: Route,
//     segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
//     return true;
//   }
// }
import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../_services/auth.service';
import { filter, map, take } from 'rxjs/operators';
 
@Injectable({
  providedIn: 'root'
})
export class AutoLoginGuard implements CanLoad {
	constructor(
		private authService: AuthService, 
		private router: Router
	) { 
		// console.log('init autoLogin Guard')
	}

	canLoad(): Observable<boolean> {    
		return this.authService.isAuthenticated.pipe(
			filter(val => val !== null), // Filter out initial Behaviour subject value
			take(1), // Otherwise the Observable doesn't complete!
			map(isAuthenticated => {
				if (isAuthenticated) {
					// console.log('=================== isAuthenticated', isAuthenticated);
					if (this.authService.locationSelected.value) {
						// console.log('=================== locationSelected', this.authService.locationSelected.value);
						// this.router.navigateByUrl('/tabs', { replaceUrl: true });
						return true;
					} else {
						// console.log('=================== locationSelected', this.authService.locationSelected.value);
						this.router.navigateByUrl('/select-location', { replaceUrl: true });
						return false;
					}
				} else {          
					// console.log('=================== isAuthenticated => ', isAuthenticated);
					this.router.navigateByUrl('/login', { replaceUrl: true });
					return false;
				}
			})
		);
	}
}