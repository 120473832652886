import { catchError } from "rxjs/operators";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "@/_services/auth.service";
import { environment } from "environments/environment";
import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root",
})
export class LocationService {
	private API_URL = environment.api_url;

	constructor(public authService: AuthService, private http: HttpClient) {}

	public addLocation(payload: any) {
		return this.http
			.post<any>(`${this.API_URL}/add-location`, payload)
			.pipe(
				map((result) => {
					return result;
				}),
				// catchError(AuthService.handleError)
			);
	}
	public updateLocation(payload: any) {
		return this.http
			.post<any>(`${this.API_URL}/update-location`, payload)
			.pipe(
				map((result) => {
					return result;
				}),
				// catchError(AuthService.handleError)
			);
	}
}
