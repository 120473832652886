interface Permission {
	signIn: boolean;
	bookGuest: boolean;
	manageTags: boolean;
	manageUsers: boolean;
	manageGroups: boolean;
	manageOwnLocation: boolean;
	manageAllLocations: boolean;
	manageAccount: boolean;
	viewLogs: boolean;
}

export class User {
	public static STATUS_ENABLED = "E";
	public static STATUS_DISABLED = "D";
	public static STATUS_NOT_ACTIVATED = "NA";
	public static STATUS_ACTIVATION_PENDING = "AP";

	id: number;
	userId: string;
	initials: string;
	firstName: string;
	lastName: string;
	fullName: string;
	name?: string;
	email: string;
	phonePrefix: string;
	phone: string;
	photo: string;
	status: string;
	groupId: number | null;
	locationId: number;
	clientId: number;
	permission: Permission;
	phonePrefixId: number;
	approveGuests: boolean;
	lastModified: string;
	constructor(
		id: number,
		userId: string,
		firstName: string,
		lastName: string,
		fullName: string,
		email: string,
		phonePrefix: string,
		phone: string,
		photo: string,
		status: string,
		groupId: number | null,
		locationId: number,
		clientId: number,
		permission: Permission,
		phonePrefixId: number,
		approveGuests: boolean
	) {
		this.id = id;
		this.userId = userId;
		this.firstName = firstName;
		this.lastName = lastName;
		this.fullName = fullName;
		this.email = email;
		this.phonePrefix = phonePrefix;
		this.phone = phone;
		this.photo = photo;
		this.status = status;
		this.groupId = groupId;
		this.locationId = locationId;
		this.clientId = clientId;
		this.permission = permission;
		this.phonePrefixId = phonePrefixId;
		this.approveGuests = approveGuests;
	}
}
