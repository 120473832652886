import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { HttpClient } from "@angular/common/http";
import { HttpLoaderFactory } from "@/_services/http-loader-factory";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";

import { SelectUserModal } from '@/_modals/select-user-modal/select-user-modal';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
	],
	declarations: [ SelectUserModal ]
})
export class SelectUserModalModule {}
