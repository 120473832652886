export class UserLog {
	id: number;
	userId: string;
	userName: string;
	accessDate: any;
	timeIn: string;
	timeOut: string;
	tagNumber: string;
	status: string;
	clientId: string;
	locationId: string;
	signedInBy: string;
	subLocationId: number;
	displayDate: string;
	displayTimeIn: string;
	displayTimeOut: string;

	constructor(
		id: number,
		userId: string,
		userName: string,
		accessDate: any,
		timeIn: string,
		timeOut: string,
		tagNumber: string,
		status: string,
		locationId: string,
		signedInBy: string,
		subLocationId: number
	) {
		this.id = id;
		this.userId = userId;
		this.userName = userName;
		this.accessDate = accessDate;
		this.timeIn = timeIn;
		this.timeOut = timeOut;
		this.tagNumber = tagNumber;
		this.status = status;
		this.locationId = locationId;
		this.signedInBy = signedInBy;
		this.subLocationId = subLocationId;
	}
}
