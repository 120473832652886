export interface GuestDetail {
	email: string;
	phone: string;
}

export class Guest {
	id: number;
	guestId: string;
	initials: string;
	name: string;
	email: string;
	phone: string;
	address: string;
	photo: string;
	verified: boolean;
	guestData: string;
	mergeId: string;
	visitCount: number;
	clientId: number;
	relatedHostIds: any[];
	relatedLocationId: number[];
	relatedSubLocationId: number[];
	lastVisit: string;
	lastModified: string;
	
	constructor(
		id: number, 
		guestId: string, 
		name: string, 
		email: string, 
		phone: string, 
		address: string, 
		photo: string ,
		verified: boolean,
		mergeId: string
	) {
		this.id = id;
		this.guestId = guestId;
		this.name = name;
		this.email = email;
		this.phone = phone;
		this.address = address;
		this.photo = photo;
		this.verified = verified;
		this.mergeId = mergeId;
	}

}
