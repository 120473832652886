export class Group {
	id: number;
	name: string;
	clientId: number;
	status: string;
	
	constructor(
		id: number,
		name: string,
		clientId: number,
		status: string
	) {
		this.id = id;
		this.name = name;
		this.clientId = clientId;
		this.status = status;
	}
		
}
