import { Component } from '@angular/core';
import { AuthService, AppDataService } from '../../_services';
import { AlertController, PopoverController } from "@ionic/angular";
import { Router } from '@angular/router';
// import { Guests } from '@/tabs/guests/guests';

@Component({
	selector: 'app-global-menu',
	template: `
	<ion-content>
		<ion-list>
			<ion-item button lines="none" detail="false" (click)="goToSettings()">
				<ion-icon name="settings-outline" class="font-32 thick" slot="start"></ion-icon>
				<ion-label>
					Settings
				</ion-label>
			</ion-item>
			<ion-item button lines="none" detail="false" (click)="confirmAction()">
				<ion-icon name="power" class="font-32 thick" slot="start"></ion-icon>
				<ion-label>
					Sign Out
				</ion-label>
			</ion-item>
		</ion-list>
	</ion-content>
	`,
	// styles: [
	// 	`ion-icon { font-size: 24px !important; }
	// 	ion-icon { font-size: 24px !important; }`
	// ]
})
export class AppMenuModule {
	constructor (
		private router: Router, 
		private alert: AlertController,
		private appdata: AppDataService,
		private authService: AuthService,
		public popoverCtrl: PopoverController
	) { }
	
	close() {
		this.popoverCtrl.dismiss();
	}
	async signOut() {
		await this.authService.logout();
		this.appdata.unsetAppData();
		this.router.navigateByUrl('/login', { replaceUrl: true });
	}
	goToSettings() {
		this.close();
		// this.router.navigateByUrl('/settings', { replaceUrl: false });
	}
	async confirmAction() {
		this.close();
		const alert = await this.alert.create({
			mode: 'ios',
			cssClass: 'null',
			header: 'Sign Out',
			message: '<span><ion-icon name="person-circle"></ion-icon>' + this.currentUser.fullName + '</span><br/>' 
					+ '<span><ion-icon name="mail-outline"></ion-icon>' + this.currentUser.email + '</span><br/>'
					+ '<span><ion-icon name="call-outline"></ion-icon>' + this.currentUser.phonePrefix + this.currentUser.phone + '</span><br/>',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
					cssClass: 'left',
					handler: (blah) => {
						console.log(' Cancel Sign-out: => ', blah);
					}
				}, {
					text: 'Sign Out',
					cssClass: 'right delete',
					handler: async () => {
						await this.signOut();
					}
				}
			]
		});
		await alert.present();
	}

	get currentUser() {
		return this.authService.currentUser.value;
	}


}