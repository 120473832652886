import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SettingsGuard } from './_guards/settings.guard';
import { AutoLoginGuard } from './_guards/auto-login.guard';
import { LoginGuard } from './_guards/login.guard';
import { AuthGuard } from './_guards';

const routes: Routes = [
	{
		path: 'login',
		// canLoad: [LoginGuard],
		canActivate: [LoginGuard],
		loadChildren: () => import('./login/login.module').then( m => m.LoginModule),
		// canLoad: [IntroGuard], // Check if to show Intro or forward to app
		data: {
			mode: "login",
		},
	},
	{
		path: 'home',
		// canLoad: [AuthGuard], // Secure childern
		canActivate: [AuthGuard], // Secure childern
		loadChildren: () => import('./home/home.module').then( m => m.HomeModule),
	},
	{
		path: 'settings',
		loadChildren: () => import('./settings/settings.module').then( m => m.SettingsModule),
		canLoad: [AutoLoginGuard, SettingsGuard] // Secure childern
	},
	{
		path: '',
		redirectTo: 'home',
		pathMatch: 'full'
	},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
