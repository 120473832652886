export interface Billable {
	id: number;
	invoiceNumber: any;
	description: string;
	quantity: number;
	unitCost: any;
	total: any;
	invoiceIndex: any;
}

export class Invoice {
	public static STATUS_PAID = 'PAID';
	public static STATUS_PENDING = 'PENDING';
	public static STATUS_OVERDUE = 'OVERDUE';
	
	id: number;
	issueDate: string;
	paymentDate: string;
	description: string;
	invoiceNumber: number;
	status: string;
	billables: Billable[];
	issueTimestamp: string;

	constructor(
		id: number,
		issueDate: string,
		paymentDate: string,
		description: string,
		invoiceNumber: number,
		status: string
	) {
		this.id = id;
		this.issueDate = issueDate;
		this.paymentDate = paymentDate;
		this.description = description;
		this.invoiceNumber = invoiceNumber;
		this.status = status;
	}
}

