import { Location } from './location';

export class Client {
	id: number;
	name: string;
	shortName: string;
	groups: any;
	locations: Location[];
	subscription: any;
	
	constructor(
		id: number,
		name: string,
		shortName: string,
		groups: any,
		locations: Location[],
		subscription: any,
	) {
		this.id = id;
		this.name = name;
		this.shortName = shortName;
		this.groups = groups;
		this.locations = locations;
		this.subscription = subscription;
	}
		
}
